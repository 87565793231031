import { Controller } from "@hotwired/stimulus"
import { post } from '@rails/request.js'

// Connects to data-controller="validate-discounts"
export default class extends Controller {
  static targets = ['error', 'success', 'promocode', 'input']
  static values = { 
    url: String,
    sendRequest: { type: Boolean, default: false }
   }

   connect() {
    this.inputTarget.value = ''
    this.promocodeTarget.value = ''
    this.inputTarget.disabled = false
  }
  
  addPromocode(event) {
    event.preventDefault()
    let promocode = this.inputTarget.value.trim()
    console.log(promocode)
    if (promocode.length < 1 || this.sendRequestValue) return
    this.sendRequestValue = true
    this.validate(promocode)
  }
  
  async validate(promocode) {
    const promocodeData = { promocode: promocode }
    const response = await post(this.urlValue, {
      body: JSON.stringify(promocodeData),
      responseKind: 'json'
    })
    if (response.ok) {
      const responseData = await response.json;
      if (responseData.status !== 'error') {
        this.successTarget.textContent = responseData.message
        this.errorTarget.textContent = ''
        this.successTarget.classList.remove('d-none')
        this.errorTarget.classList.add('d-none')
        this.promocodeTarget.value = responseData.discount_id;
        this.inputTarget.disabled = true
        сonsole.log('Success data:', responseData);
      } else {
        this.errorTarget.textContent = responseData.message
        this.successTarget.textContent = ''
        this.errorTarget.classList.remove('d-none')
        this.successTarget.classList.add('d-none')
        this.promocodeTarget.value = ''
        this.inputTarget.value = ''
        if (this.inputTarget.disabled) this.inputTarget.disabled = false
        this.inputTarget.focus()
        console.error('Error data:', responseData.errorData);
      }
    }
    this.sendRequestValue = false
  }
}